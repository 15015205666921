import { Autocomplete, SxProps, TextField } from '@mui/material'
import { trpc } from '../../core/trpc'
import { Wallet } from '../../types/procedureOutputs'

type WalletAutocompleteProps = {
  value: string | null
  onChange: (wallet: Wallet | null) => void
  sx?: SxProps
  disabled?: boolean
}

const WalletAutocomplete = ({ value, onChange, sx = {}, disabled = false }: WalletAutocompleteProps) => {
  const { data: wallets } = trpc.wallet.getWallets.useQuery()
  const autocompleteVal = wallets?.find((wallet) => wallet.id === value)
  return (
    <Autocomplete
      data-cy="walletAutocomplete"
      sx={{ width: '150px', ...sx }}
      disabled={disabled}
      value={autocompleteVal || null}
      isOptionEqualToValue={(val, option) => val.id === option.id}
      getOptionLabel={(option) => option.name}
      options={wallets || []}
      onChange={(_, value) => onChange(value)}
      renderInput={(params) => <TextField {...params} label="Wallet" />}
    />
  )
}

export default WalletAutocomplete
