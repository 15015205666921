import { Box, Button, Dialog, Divider, IconButton, MenuItem, TextField, TextareaAutosize, Tooltip, Typography } from '@mui/material'
import { AlertCircle, DollarSign, Info, X } from 'react-feather'
import { trpc } from '../../../../../core/trpc'
import { formatCurrency } from '../../../../../utils/helpers'
import { LoadingButton } from '@mui/lab'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { date, InferType, object, string } from 'yup'
import toast from 'react-hot-toast'
import { formatBankAccount } from '../../../../../utils/transactionUtils'
import Decimal from 'decimal.js'
import { decimalSchema } from '../../../../../utils/accounting'
import UserBadge from '../../../../../components/UserBadge'
import { useEffect, useMemo, useState } from 'react'
import WalletAutocomplete from '../../../../../components/Accounting/WalletAutocomplete'
import { DatePicker } from '@mui/x-date-pickers'

type Props = {
  userId: string | undefined
  onClose: () => void
  refetch: () => void
  isProfit: boolean // profit = partner/subsidiary
  walletId?: string | undefined
}

const PayBrokerModal = ({ userId, onClose, refetch, isProfit, walletId }: Props) => {
  const [wallet, setWallet] = useState<string | undefined>(undefined)
  const { data: user } = trpc.user.user.useQuery({ id: userId })
  const { data: franklinAccounts } = trpc.franklinBankAccount.getFranklinBankAccounts.useQuery()
  const { data: bankAccounts } = trpc.bankAccount.getBankAccounts.useQuery({ userId }, { enabled: Boolean(userId) })
  const { data: availableMoney } = trpc.brokersAccounting.getBrokersAvailableAmount.useQuery(
    { user_ids: [userId as string], is_profit: isProfit, wallet_id: walletId || wallet },
    { enabled: Boolean(userId) }
  )
  const mutation = trpc.userPayment.createUserPayment.useMutation({
    onError: (e) => toast.error(String(e)),
    onSuccess: () => {
      toast.success('successfully created payment')
      refetch()
      onClose()
    }
  })

  const userAvailableMoney = availableMoney?.[userId as string]

  const formSchema = object({
    paymentAccountId: string().required(),
    bankAccountId: string().required(),
    note: string(),
    amount: decimalSchema()
      .required()
      .test({
        message: `Amount cannot be greater than ${formatCurrency(userAvailableMoney?.total || 0)}`,
        test: (val) => val.lessThanOrEqualTo(new Decimal(userAvailableMoney?.total || 0))
      }),
    walletId: string().required(),
    date: date()
  })

  type SchemaType = InferType<typeof formSchema>
  const today = useMemo(() => new Date(), [])

  const { control, handleSubmit, setValue } = useForm({
    resolver: yupResolver<SchemaType>(formSchema),
    defaultValues: {
      paymentAccountId: '',
      bankAccountId: bankAccounts?.find((account) => account.primary_user?.id === userId)?.id || '',
      note: '',
      amount: new Decimal(userAvailableMoney?.total || 0),
      walletId: walletId,
      date: today
    }
  })

  const submit = (data: SchemaType) => {
    mutation.mutate({
      user_id: userId || '',
      franklin_bank_account_id: data.paymentAccountId,
      user_bank_account_id: data.bankAccountId,
      note: data.note,
      amount: data.amount,
      isProfit,
      wallet_id: data.walletId,
      date: data.date
    })
  }

  useEffect(() => {
    const userAvailableMoney = availableMoney?.[userId as string]
    if (userAvailableMoney) {
      setValue('amount', new Decimal(userAvailableMoney.total || 0))
    }
  }, [availableMoney, setValue, userId])

  return (
    <Dialog open={Boolean(userId)} maxWidth="xl">
      <form onSubmit={handleSubmit(submit)}>
        <Box className="w-[500px] mobile:w-full p-5 flex flex-col gap-6">
          <Box className="flex justify-between items-center">
            <Typography className="text_lg_semibold">Pay {isProfit ? 'Partner' : 'Broker'}</Typography>
            <IconButton size="small" onClick={onClose}>
              <X size={16} />
            </IconButton>
          </Box>
          {isProfit && (
            <Box className="p-3 bg-warning-100 border-[1px] border-warning-200 border-solid rounded-lg mobile:w-[100px] flex items-start gap-2">
              <AlertCircle size={34} className="text-warning-800" />
              <Typography className="text-warning-800">
                You are about to pay {user?.name}, a {user?.owner_of_subsidiary?.is_parent_subsidiary ? 'Partner' : 'Subsidiary'}, the amount shown
                below. Please review the details before proceeding.
              </Typography>
            </Box>
          )}
          <Box className="p-3 bg-grey-50 rounded-lg mobile:w-[100px]">
            <Box className="flex items-center gap-[88px] mobile:flex-col mobile:items-start mobile:gap-1 mb-2">
              <Typography className="text-grey-700 mb-2">Broker</Typography>
              <Box className="flex gap-2 items-center">
                <Typography className="text_md_medium">{user?.name || ''}</Typography>
                {isProfit && (
                  <Tooltip title={user?.owner_of_subsidiary?.is_parent_subsidiary ? 'Partner' : 'Subsidiary'}>
                    <Box>
                      <UserBadge type={user?.owner_of_subsidiary?.is_parent_subsidiary ? 'partner' : 'subsidiary'} />
                    </Box>
                  </Tooltip>
                )}
              </Box>
            </Box>
            <Box className="flex items-start gap-8 mobile:flex-col mobile:gap-1">
              <Typography className="text-grey-700">Amount Owed</Typography>
              <Box>
                <Typography className="text_md_medium mb-2 mt-0.5">{formatCurrency(userAvailableMoney?.total || '')}</Typography>
                <Box className="border-warning-200 border-solid border-[1px] rounded-lg bg-warning-25 p-2">
                  <Box className="flex items-center gap-2  mb-2">
                    <Info size={18} className="text-warning-500" />
                    <Typography className="text_sm_medium text-warning-600">Amount Breakdown</Typography>
                  </Box>
                  <Box className="flex gap-2">
                    <Box className="flex flex-col gap-0.5">
                      <Typography data-cy="commissions" className="text_sm_medium text-warning-900 mb-1">
                        {formatCurrency(userAvailableMoney?.COMMISSION || '')}
                      </Typography>
                      <Typography data-cy="serviceFees" className="text_sm_medium text-warning-900 mb-1">
                        {formatCurrency(userAvailableMoney?.SERVICE_FEE || '')}
                      </Typography>
                      <Typography data-cy="bonus" className="text_sm_medium text-warning-900 mb-1">
                        {formatCurrency(userAvailableMoney?.BONUS || '')}
                      </Typography>
                      <Typography data-cy="expenses" className="text_sm_medium text-warning-900">
                        {formatCurrency(userAvailableMoney?.EXPENSE || '')}
                      </Typography>
                      <Typography data-cy="payback" className="text_sm_medium text-warning-900">
                        {formatCurrency(userAvailableMoney?.DRAW || '')}
                      </Typography>
                      <Typography className="text_sm_medium text-warning-900">{formatCurrency(userAvailableMoney?.PAYBACK || '')}</Typography>
                    </Box>
                    <Box className="flex flex-col gap-0.5">
                      <Typography className="text_sm_medium text-warning-900 mb-1">commissions</Typography>
                      <Typography className="text_sm_medium text-warning-900 mb-1">service fees</Typography>
                      <Typography className="text_sm_medium text-warning-900 mb-1">bonus</Typography>
                      <Typography className="text_sm_medium text-warning-900">expenses</Typography>
                      <Typography className="text_sm_medium text-warning-900">draw</Typography>
                      <Typography className="text_sm_medium text-warning-900">custom payment</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box>
            <Typography className="text-grey-700 mb-1.5">Bank Account</Typography>
            <Controller
              control={control}
              name="bankAccountId"
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <TextField
                  data-cy="bankAccountInput"
                  error={Boolean(error)}
                  helperText={error?.message || ''}
                  fullWidth
                  label="Select Account"
                  value={value}
                  select
                  onChange={onChange}
                >
                  {bankAccounts?.map((account) => (
                    <MenuItem key={account.id} value={account.id}>
                      {formatBankAccount({ name: account.bank_name, account_number: account.account_number })}
                    </MenuItem>
                  )) || []}
                </TextField>
              )}
            />
          </Box>
          <Box>
            <Typography className="text-grey-700 mb-1.5">Amount Being Paid</Typography>
            <Controller
              control={control}
              name="amount"
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <TextField
                  data-cy="amountInput"
                  InputProps={{ startAdornment: <DollarSign size={18} className="text-grey-500" /> }}
                  type="number"
                  fullWidth
                  value={value.toString() || ''}
                  onChange={onChange}
                  helperText={error?.message || ''}
                  error={Boolean(error)}
                />
              )}
            />
          </Box>
          <Box>
            <Typography className="text-grey-700 mb-1.5">Payment Account</Typography>
            <Controller
              control={control}
              name="paymentAccountId"
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <TextField
                  data-cy="paymentAccountInput"
                  error={Boolean(error)}
                  helperText={error?.message || ''}
                  fullWidth
                  value={value}
                  select
                  onChange={onChange}
                >
                  {franklinAccounts?.map((account) => (
                    <MenuItem key={account.id} value={account.id}>
                      {formatBankAccount(account)}
                    </MenuItem>
                  )) || []}
                </TextField>
              )}
            />
          </Box>
          <Box>
            <Typography className="text-grey-700 mb-1.5">Wallet</Typography>
            <Controller
              control={control}
              name="walletId"
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <WalletAutocomplete
                  value={value}
                  onChange={(wallet) => {
                    onChange(wallet?.id)
                    setWallet(wallet?.id)
                  }}
                  disabled={!!walletId}
                  sx={{ width: '100%' }}
                />
              )}
            />
          </Box>
          <Box>
            <Typography className="text-grey-700 mb-1.5">Payment Date</Typography>
            <Controller
              control={control}
              name="date"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <DatePicker
                  maxDate={today}
                  slotProps={{ textField: { fullWidth: true, error: Boolean(error), helperText: error?.message || '', className: 'datePicker' } }}
                  value={value ? new Date(value) : null}
                  onChange={onChange}
                />
              )}
            />
          </Box>
          <Box>
            <Typography className="text-grey-700 mb-1.5">Notes</Typography>
            <Controller
              control={control}
              name="note"
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <TextareaAutosize className="w-full rounded-lg shadow-sm p-3" minRows={1} value={value} onChange={onChange} />
              )}
            />
          </Box>
          <Divider />
          <Box className="flex justify-end gap-2.5">
            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>
            <LoadingButton data-cy="saveButton" loading={mutation.isLoading} type="submit" variant="contained">
              Save
            </LoadingButton>
          </Box>
        </Box>
      </form>
    </Dialog>
  )
}

export default PayBrokerModal
