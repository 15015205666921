import { AccountingUserType, InvoiceStatus, TermFrequency, User, Role } from '@prisma/client'
import { addWeeks, addMonths, addYears, addBusinessDays } from 'date-fns'
import { Decimal } from 'decimal.js'
import * as Yup from 'yup'
import { type DefaultSession, ISODateString } from 'next-auth'
import { brokerRoleName } from '../consts/roles'

export type AccountingFilter = {
  search: string
  status: 'all' | 'in_progress' | 'paid' | 'owed'
  userId: string
  subsidiary?: { id: string; name: string } | undefined
  startDate: Date | null
  endDate: Date | null
  walletId: string | null
}

interface SessionData extends DefaultSession {
  expires: ISODateString
  user: User & { subordinatesCount: number; subsidiaries: string[] | null; isSubsidiaryPartner: boolean; is_owner_of_parent_subsidiary: boolean }
  account: { microsft_user_id: string; id: string; provider: string } | undefined
  currentRole: Role | null
}

const getCommissionAmount = (offer: { final_purchase_price: string | Decimal | null; commission_points: string | Decimal | null }) => {
  if (!offer.final_purchase_price || !offer.commission_points) return undefined
  return new Decimal(offer.final_purchase_price).times(new Decimal(offer.commission_points).div(100)).toDecimalPlaces(2)
}

const getNumberOfPayments = (offer: {
  period_payment_amount: Decimal | string | null
  final_purchase_price: Decimal | string | null
  final_purchase_amount: Decimal | string | null
  buy_rate: Decimal | string | null
}) => {
  if (!Number(offer.final_purchase_amount) || !Number(offer.period_payment_amount)) return 0
  return Number(new Decimal(offer.final_purchase_amount || 0).div(new Decimal(offer.period_payment_amount || 0)).ceil())
}

const getOfferInvoiceStatus = (invoices: { status: InvoiceStatus }[]): InvoiceStatus => {
  const statuses = invoices.map((i) => i.status)

  const hasPartialPay = statuses.includes('PARTIAL_PAYMENT')
  const hasUnpaid = statuses.includes('UNPAID')
  const hasPaid = statuses.includes('PAID') || statuses.includes('REVERSE') || statuses.includes('EPO') || statuses.includes('CLOSED')

  if (hasPartialPay) return InvoiceStatus.PARTIAL_PAYMENT
  if (hasUnpaid && hasPaid) return InvoiceStatus.PARTIAL_PAYMENT
  if (hasUnpaid) return InvoiceStatus.UNPAID

  if (statuses.includes('REVERSE')) return InvoiceStatus.REVERSE
  if (statuses.includes('EPO')) return InvoiceStatus.EPO
  if (statuses.includes('CLOSED')) return InvoiceStatus.CLOSED
  return InvoiceStatus.PAID
}

type GetLoanMaturityParams =
  | {
      period_payment_frequency: TermFrequency | null
      final_purchase_amount: Decimal | string
      number_of_payments: number
      payment_start_date: Date
    }
  | {
      period_payment_frequency: TermFrequency | null
      period_payment_amount: Decimal | string
      final_purchase_price: Decimal | string
      final_purchase_amount: Decimal | string
      payment_start_date: Date
      buy_rate: Decimal | string
    }

const getLoanMaturitytNumberOfPayments = (params: GetLoanMaturityParams) => {
  if ('number_of_payments' in params) return params.number_of_payments
  else return getNumberOfPayments(params)
}

const getLoanMaturityDate = (params: GetLoanMaturityParams) => {
  const number_of_payments = getLoanMaturitytNumberOfPayments(params)
  const { period_payment_frequency, payment_start_date } = params
  if (period_payment_frequency === TermFrequency.DAILY) return addBusinessDays(payment_start_date, number_of_payments)
  if (period_payment_frequency === TermFrequency.WEEKLY) return addWeeks(payment_start_date, number_of_payments)
  if (period_payment_frequency === TermFrequency.BIWEEKLY) return addWeeks(payment_start_date, number_of_payments * 2)
  if (period_payment_frequency === TermFrequency.MONTHLY) return addMonths(payment_start_date, number_of_payments)
  if (period_payment_frequency === TermFrequency.BIMONTHLY) return addMonths(payment_start_date, number_of_payments * 2)
  if (period_payment_frequency === TermFrequency.QUARTERLY) return addMonths(payment_start_date, number_of_payments * 3)
  if (period_payment_frequency === TermFrequency.BIYEARLY) return addYears(payment_start_date, number_of_payments * 2)
  if (period_payment_frequency === TermFrequency.YEARLY) return addYears(payment_start_date, number_of_payments)
  return payment_start_date
}

const getInvoiceDueDate = (startDate: string, numberOfPayments: number, frequency: TermFrequency | null) => {
  const date = new Date(startDate)
  if (frequency === TermFrequency.DAILY) return addBusinessDays(date, numberOfPayments)
  if (frequency === TermFrequency.WEEKLY) return addWeeks(date, numberOfPayments)
  if (frequency === TermFrequency.BIWEEKLY) return addWeeks(date, numberOfPayments * 2)
  if (frequency === TermFrequency.MONTHLY) return addMonths(date, numberOfPayments)
  if (frequency === TermFrequency.BIMONTHLY) return addMonths(date, numberOfPayments * 2)
  if (frequency === TermFrequency.QUARTERLY) return addMonths(date, numberOfPayments * 3)
  if (frequency === TermFrequency.BIYEARLY) return addYears(date, numberOfPayments * 2)
  if (frequency === TermFrequency.YEARLY) return addYears(date, numberOfPayments)
  return date
}

const getPercentage = ({ value, total }: { total: Decimal | string | undefined; value: Decimal | string | undefined }) => {
  const percentage = new Decimal(value || 0).div(new Decimal(total || 1)).mul(100)
  return percentage || new Decimal(0)
}

const decimalSchema = () =>
  Yup.mixed<Decimal>().transform((value, originalValue) => {
    if (originalValue === null) return originalValue
    if (originalValue === undefined || originalValue === '') {
      return undefined
    }
    return new Decimal(value)
  })

const getInvoiceBalance = (total: Decimal, amount_received: Decimal, clawback: Decimal) => {
  return total.minus(amount_received).minus(clawback.abs())
}

type GetPartnerCommissionProps = {
  serviceFeeInvoiceAmount: Decimal | undefined
  commissionInvoiceAmount: Decimal
  numOfPartners: number
  totalNonProfitPercentage: Decimal
  subsidiaryFlatRate: Decimal
  subsidiaryPercentage: Decimal
}

const getPartnerSplitForAllInvoices = ({
  numOfPartners,
  totalNonProfitPercentage,
  serviceFeeInvoiceAmount,
  commissionInvoiceAmount,
  subsidiaryFlatRate,
  subsidiaryPercentage
}: GetPartnerCommissionProps) => {
  const serviceFeePartnerSplit = serviceFeeInvoiceAmount
    ? calculatePartnerSplit({
        invoiceAmount: serviceFeeInvoiceAmount,
        totalPercentage: totalNonProfitPercentage,
        numOfPartners,
        subsidiaryFlatRate,
        subsidiaryPercentage
      })
    : new Decimal(0)
  const commissionPartnerSplit = calculatePartnerSplit({
    invoiceAmount: commissionInvoiceAmount,
    totalPercentage: totalNonProfitPercentage,
    numOfPartners,
    subsidiaryFlatRate,
    subsidiaryPercentage
  })
  return {
    serviceFeePartnerSplit,
    commissionPartnerSplit,
    percentage: calculatePartnerPercentage({ invoiceAmount: commissionInvoiceAmount, numOfPartners, subsidiaryFlatRate, subsidiaryPercentage })
  }
}

const calculatePartnerSplit = (props: {
  invoiceAmount: Decimal
  totalPercentage: Decimal
  subsidiaryPercentage: Decimal
  subsidiaryFlatRate?: Decimal
  numOfPartners: number
}) => {
  const { invoiceAmount, totalPercentage, subsidiaryPercentage, numOfPartners, subsidiaryFlatRate } = props
  const totalBrokersCommissionAmount = invoiceAmount.times(totalPercentage).div(100)
  const remainderForSubsidiary = invoiceAmount.minus(totalBrokersCommissionAmount)
  const subsidiaryAmount = subsidiaryFlatRate?.toNumber() || remainderForSubsidiary.times(subsidiaryPercentage).div(100)
  const remainderForPartner = remainderForSubsidiary.minus(subsidiaryAmount)
  const partnerSplitAmount = remainderForPartner.div(numOfPartners)
  return partnerSplitAmount
}

const calculateSubsidiaryAmount = ({
  invoiceAmount,
  totalNonProfitPercentage,
  subsidiaryPercentage,
  subsidiaryFlatRate
}: {
  invoiceAmount: Decimal
  totalNonProfitPercentage: Decimal
  subsidiaryPercentage: Decimal
  subsidiaryFlatRate?: Decimal
}) => {
  const totalBrokersCommissionAmount = invoiceAmount.times(totalNonProfitPercentage).div(100)
  const remainderForSubsidiary = invoiceAmount.minus(totalBrokersCommissionAmount)
  const subsidiaryAmount = subsidiaryFlatRate?.toNumber() ? subsidiaryFlatRate : remainderForSubsidiary.times(subsidiaryPercentage).div(100)
  return subsidiaryAmount
}

const calculatePartnerPercentage = ({
  invoiceAmount,
  subsidiaryPercentage,
  numOfPartners,
  subsidiaryFlatRate
}: {
  invoiceAmount: Decimal
  subsidiaryPercentage: Decimal
  subsidiaryFlatRate: Decimal
  numOfPartners: number
}) => {
  if (subsidiaryFlatRate.toNumber()) {
    const partnerDollarAmount = invoiceAmount.minus(subsidiaryFlatRate).div(numOfPartners)
    const partnerPercentage = partnerDollarAmount.div(invoiceAmount).times(100).toDecimalPlaces(2)
    return partnerPercentage
  } else {
    return new Decimal(100).minus(subsidiaryPercentage).div(numOfPartners).toDecimalPlaces(2)
  }
}

const isSubsidiaryUserType = (userType: AccountingUserType) =>
  ([AccountingUserType.LEAD_FEE, AccountingUserType.PARTNER, AccountingUserType.SUBSIDIARY] as AccountingUserType[]).includes(userType)

const hasBroker = (roles: string[]) => roles.map((role) => role.toLowerCase()).includes(brokerRoleName)

const isBroker = (session: SessionData | null) => session && session.currentRole?.name && session.currentRole?.name.toLowerCase() === brokerRoleName

const isTeamLead = (session: SessionData | null): boolean =>
  Boolean(session && !isBroker(session) && session.user?.subordinatesCount && session.user?.subordinatesCount > 0)

export {
  decimalSchema,
  isSubsidiaryUserType,
  getCommissionAmount,
  getNumberOfPayments,
  getOfferInvoiceStatus,
  getInvoiceDueDate,
  getLoanMaturityDate,
  getPercentage,
  getInvoiceBalance,
  getPartnerSplitForAllInvoices,
  calculatePartnerSplit,
  calculateSubsidiaryAmount,
  hasBroker,
  isBroker,
  isTeamLead
}
