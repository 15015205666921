import React, { useCallback, useEffect, useState } from 'react'
import { Box, LinearProgress, MenuItem, Select, SelectChangeEvent, Tooltip } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { MoreHorizIcon } from '../../../assets/icons'
import { Dialog, DialogButton, DialogCloseButton, DialogHeader, DialogSheet, DialogTitle } from '../../Dialog'
import { EditField } from './EditField'
import { FileOutput } from '../../../types/procedureOutputs'
import mime from 'mime'
import { toast } from 'react-hot-toast'
import { isExcelFile } from '../../../utils'
import axios from 'axios'
import { trpc } from '../../../core/trpc'
import { useSession } from 'next-auth/react'
import { FileViewerMenu, FileViewerMenuActions } from './FileViewerMenu'
import { downloadFileFromLink } from '../../../models/file'
import bytesToSize from '../../../utils/bytesToSize'
import CheckCircle from '../../../icons/CheckCircle'

const CHROME_FILE_VIEWER_BG = '#525659'

type FileViewerProps = {
  /**
   *  file *must* be defined when open is true
   */
  file?: FileOutput
  refetch: () => void

  open: boolean
  onClose: () => void

  hasPrevFile: boolean
  onPrevFile: () => void
  hasNextFile: boolean
  onNextFile: () => void
}

const MONTHS_LIST = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
const FILE_TYPES = [
  'Application',
  'AR Report',
  'Bank Statement',
  'Credit Card Statement',
  'Debt sheet',
  'Tax Return',
  'Contract File',
  'Driver License',
  'Voided Check'
]

const defaultFilePreviewState = Object.freeze({
  progress: 0,
  blob: null,
  error: null
})

const FileViewerContents = ({ file, refetch, onClose, onPrevFile, hasPrevFile, onNextFile, hasNextFile }: FileViewerProps) => {
  const viewFilePreference = useSession().data?.user.file_view_preference

  const [optionsAnchorEl, setOptionsAnchorEl] = useState<HTMLElement | null>(null)
  const [selectedFileType, setSelectedFileType] = useState<string>('')
  const [selectedMonth, setSelectedMonth] = useState<string>('')
  const [isEditingFilename, setIsEditingFilename] = useState(false)
  const [filename, setFilename] = useState<string>(file!.name ?? '')
  const [isEditingDescription, setIsEditingDescription] = useState(false)
  const [description, setDescription] = useState<string>(file?.description ?? '')

  const [filePreview, setFilePreview] = useState<{
    progress: number
    blob: string | null
    error: string | null
  }>(defaultFilePreviewState)

  const inferFileTypeAndMonth = useCallback((filename: string) => {
    const inferredFileType = FILE_TYPES.find((type) => filename.toLowerCase().includes(type.toLowerCase())) ?? ''
    const inferredMonth = MONTHS_LIST.find((month) => filename.toLowerCase().includes(month.toLowerCase())) ?? ''
    return { inferredFileType, inferredMonth }
  }, [])

  useEffect(() => {
    if (!file) return

    const { inferredFileType, inferredMonth } = inferFileTypeAndMonth(file.name)
    setSelectedFileType(inferredFileType)
    setSelectedMonth(inferredMonth)
    setFilename(file.name)
    setDescription(file.description ?? '')
    setIsEditingFilename(false)
    setIsEditingDescription(false)
  }, [file])

  const updateFilename = useCallback((name: string, newFileType: string, newMonth: string) => {
    const parts = name.split(/\s*-\s*/).filter(Boolean)

    let baseName = parts.slice(0, -1).join(' - ')
    let extension = parts[parts.length - 1].split('.').pop() ?? ''

    if (!parts[parts.length - 1].includes('.')) {
      baseName = parts.join(' - ')
      extension = ''
    }

    const filteredParts = baseName
      .split(' - ')
      .filter(
        (part) =>
          !FILE_TYPES.some((type) => part.toLowerCase().includes(type.toLowerCase())) &&
          !MONTHS_LIST.some((month) => part.toLowerCase().includes(month.toLowerCase()))
      )

    if (newFileType) filteredParts.unshift(newFileType.trim())
    if (newMonth) filteredParts.push(newMonth.trim())
    const filename = filteredParts.join(' - ')
    return extension ? `${filename}.${extension}` : filename
  }, [])

  const handleSelectFileType = (e: SelectChangeEvent<string>) => {
    const newFileType = e.target.value
    setSelectedFileType(newFileType)
    const newFilename = updateFilename(filename, newFileType, selectedMonth)
    setFilename(newFilename)
    setIsEditingFilename(true)
  }

  const handleSelectMonth = (e: SelectChangeEvent<string>) => {
    const newMonth = e.target.value
    setSelectedMonth(newMonth)
    const newFilename = updateFilename(filename, selectedFileType, newMonth)
    setFilename(newFilename)
    setIsEditingFilename(true)
  }

  const handleCancelEditFilename = () => {
    setIsEditingFilename(false)
    setFilename(file!.name)
    const { inferredFileType, inferredMonth } = inferFileTypeAndMonth(file!.name)
    setSelectedFileType(inferredFileType)
    setSelectedMonth(inferredMonth)
  }

  const handleSaveEditFilename = async () => {
    updateFileMutation.mutate(
      { id: file!.id, name: filename },
      {
        onSuccess: () => {
          setFilename(filename)
          setIsEditingFilename(false)
          const { inferredFileType, inferredMonth } = inferFileTypeAndMonth(filename)
          setSelectedFileType(inferredFileType)
          setSelectedMonth(inferredMonth)
        },
        onError: () => {
          setFilename(file!.name)
          const { inferredFileType, inferredMonth } = inferFileTypeAndMonth(file!.name)
          setSelectedFileType(inferredFileType)
          setSelectedMonth(inferredMonth)
        }
      }
    )
  }

  const handleSaveEditDescription = async () => {
    updateFileMutation.mutate({ id: file!.id, description })
    setIsEditingDescription(false)
  }

  const getViewFileLink = trpc.file.viewFileLink.useMutation()
  const updateFileMutation = trpc.file.updateFile.useMutation({
    onSuccess: () => {
      refetch()
      toast.success('Successfully updated file')
    },
    onError: () => toast.error('File update failed')
  })

  const deleteFileMutation = trpc.file.deleteFile.useMutation({
    onSuccess: () => {
      refetch()
      onClose()
      toast.success('Successfully deleted file')
    },
    onError: () => toast.error('File deletion failed')
  })

  // load file on mount
  const loadFile = async () => {
    if (!file) {
      return
    }

    if (!mime.getType(file.type) && !mime.getExtension(file.type)) {
      toast.error('Unable to preview file')
      return
    }

    const type = mime.getExtension(file.type) ? file.type : (mime.getType(file.type) as string)
    if (isExcelFile(file)) {
      return
    }

    const cancelTokenSource = axios.CancelToken.source()

    try {
      setFilePreview(defaultFilePreviewState)

      const url = await getViewFileLink.mutateAsync({ id: file.id })
      const response = await axios.get<ArrayBuffer>(url, {
        cancelToken: cancelTokenSource.token,
        responseType: 'arraybuffer',
        onDownloadProgress: (progressEvent) => {
          if (progressEvent.total) {
            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            setFilePreview((p) => ({ ...p, progress }))
          }
        }
      })

      const objectUrl = URL.createObjectURL(new Blob([response.data], { type }))
      if (viewFilePreference === 'NEW_TAB') {
        const el = document.createElement('a')
        el.href = objectUrl
        el.hidden = true
        el.target = '_blank'
        el.click()
        el.remove()
      } else {
        setFilePreview((p) => ({
          ...p,
          blob: objectUrl,
          error: null
        }))
      }
    } catch (e) {
      toast.error(`Failed to get file. ${e}`)
    }

    return () => {
      cancelTokenSource.cancel('abort due to component unmount')

      setFilePreview(defaultFilePreviewState)
    }
  }

  useEffect(() => {
    loadFile()
  }, [file?.id])

  const handleMenuAction = async (action: FileViewerMenuActions) => {
    switch (action) {
      case FileViewerMenuActions.Download:
        try {
          const url = (await getViewFileLink.mutateAsync({ id: file!.id })) as unknown as string
          await downloadFileFromLink(file!, url)
        } catch (e) {
          toast.error(`Failed to download file. ${e}`)
        }
        break
      case FileViewerMenuActions.MarkAsContract:
        await updateFileMutation.mutateAsync({ id: file!.id, is_contract: !file!.is_contract })
        break

      case FileViewerMenuActions.Delete:
        await deleteFileMutation.mutateAsync({ id: file!.id })
        break
    }
  }

  return (
    <Box className="flex t:flex-row h-full">
      <Box className="flex flex-grow border-none h-full" style={{ backgroundColor: CHROME_FILE_VIEWER_BG }}>
        {filePreview.progress !== 100 && (
          <Box className="w-full flex items-center justify-center">
            <LinearProgress variant="determinate" value={filePreview.progress} color="inherit" className="w-[400px] text-white" />
          </Box>
        )}
        {filePreview.error && <Box>{filePreview.error}</Box>}
        {filePreview.blob && <iframe className="flex flex-grow border-none h-full" title={file!.name} src={filePreview.blob} />}
      </Box>

      <Box className="flex flex-col flex-grow max-w-[480px]">
        <DialogHeader
          leadingAccessory={
            <DialogTitle
              title={
                <div className="flex items-center">
                  {file!.is_contract && (
                    <Tooltip title="Contract File">
                      <CheckCircle color="success" fontSize="small" className="mr-2" />
                    </Tooltip>
                  )}
                  <span className="truncate max-w-[250px]">{file!.name}</span>
                </div>
              }
            />
          }
          trailingAccessory={
            <>
              <DialogButton onClick={onPrevFile} disabled={!hasPrevFile} Icon={ArrowBackIcon} />
              <DialogButton onClick={onNextFile} disabled={!hasNextFile} Icon={ArrowForwardIcon} />
              <DialogButton onClick={(e) => setOptionsAnchorEl(e.currentTarget)} Icon={MoreHorizIcon} />

              <DialogCloseButton onClick={onClose} />
              <FileViewerMenu file={file!} onClose={() => setOptionsAnchorEl(null)} onAction={handleMenuAction} anchorElement={optionsAnchorEl} />
            </>
          }
        />

        <Box className="p-5 pt-0">
          <Box className="flex flex-row gap-4 mb-4">
            <Select
              value={selectedFileType}
              onChange={handleSelectFileType}
              fullWidth
              size="small"
              renderValue={(v) => (v != '' ? v : 'Choose a file type')}
              displayEmpty
            >
              {FILE_TYPES.map((opt) => (
                <MenuItem onClick={(e) => e.stopPropagation()} value={opt} key={opt}>
                  {opt}
                </MenuItem>
              ))}
            </Select>
            <Select
              value={selectedMonth}
              onChange={handleSelectMonth}
              fullWidth
              size="small"
              renderValue={(v) => (v != '' ? v : 'Choose a month')}
              displayEmpty
            >
              {MONTHS_LIST.map((month) => (
                <MenuItem onClick={(e) => e.stopPropagation()} value={month} key={month}>
                  {month}
                </MenuItem>
              ))}
            </Select>
          </Box>

          <EditField
            label="Filename"
            placeholder="Select a file type and month"
            value={filename}
            isEditMode={isEditingFilename}
            editValue={filename}
            onEditValueChange={setFilename}
            onEditMode={() => setIsEditingFilename(true)}
            onCancelEdit={handleCancelEditFilename}
            onSaveEdit={handleSaveEditFilename}
          />

          <EditField
            label="Description"
            placeholder="Enter a description"
            value={file!.description ?? ''}
            isEditMode={isEditingDescription}
            editValue={description}
            onEditValueChange={setDescription}
            onEditMode={() => setIsEditingDescription(true)}
            onCancelEdit={() => setIsEditingDescription(false)}
            onSaveEdit={handleSaveEditDescription}
          />

          {file?.size && <EditField label="File Size" value={bytesToSize(file!.size)} disabled />}
          {file?.created_by?.name && <EditField label="Created by" value={file?.created_by?.name || ''} disabled />}
        </Box>

        <Box className="flex flex-grow bg-grey-100 border-0 border-t border-grey-300 border-solid">{/* placeholder for AI/Copilot */}</Box>
      </Box>
    </Box>
  )
}

export const FileViewer = (props: FileViewerProps) => {
  const { open, onClose } = props

  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogSheet className="h-full min-w-[340px] t:w-[80vw] max-w-[1440px]">
        <FileViewerContents {...props} />
      </DialogSheet>
    </Dialog>
  )
}
