import { Autocomplete, SxProps, TextField, createFilterOptions } from '@mui/material'
import { unionWith, eqBy, toLower, prop } from 'ramda'
import naics from 'naics'
import { trpc } from '../core/trpc'
import { useEffect, useState } from 'react'

type IndustryAutocompleteProps = {
  value: string
  onChange: (newValue: string | null) => void
  fullWidth?: boolean
  sx?: SxProps
}

const IndustryAutocomplete = ({ onChange, value, fullWidth, sx = {} }: IndustryAutocompleteProps) => {
  const industries = (Array.from(naics.Industry.codes()) as Industry[]).filter((industry) => industry.category === 'Subsector')
  const { data } = trpc.company.getIndustries.useQuery()
  const [totalIndustries, setTotalIndustries] = useState<Industry[]>([])

  useEffect(() => {
    if (data && industries) {
      const EmptyIndustry = (title: string) => ({
        code: '000',
        title: title,
        description: '',
        category: '',
        descendants: () => [],
        children: () => [],
        parent: () => undefined
      })

      const dbIndustries = data.map((item) => EmptyIndustry(item.industry_title || '')).filter((item) => item.title)
      const compareIndustries = eqBy((industry) => toLower(prop('title', industry) || ''))
      const totalIndustries = unionWith(compareIndustries, industries, dbIndustries)

      setTotalIndustries(totalIndustries)
    }
  }, [data, industries])

  const filterOptions = createFilterOptions<Industry>({
    limit: 10
  })

  return (
    <Autocomplete
      freeSolo
      fullWidth={fullWidth}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.title)}
      value={value}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string' || newValue === null) {
          return
        }
        if (newValue.title.startsWith('Add new industry')) {
          onChange(newValue.title.replace('Add new industry "', '').slice(0, -1))
        } else {
          onChange(newValue.title)
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filterOptions(options, params)
        const { inputValue } = params
        const isExisting = options.some((option) => inputValue && option.title.toLowerCase() === inputValue.toLowerCase())
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            code: '000',
            title: `Add new industry "${inputValue}"`,
            description: '',
            category: '',
            descendants: () => [],
            children: () => [],
            parent: () => undefined
          })
        }
        return filtered
      }}
      isOptionEqualToValue={(option, value) => (typeof value === 'string' ? true : option.code === value.code)}
      options={totalIndustries || []}
      sx={sx}
      renderInput={(params) => <TextField {...params} label="Industry" />}
    />
  )
}

export default IndustryAutocomplete
