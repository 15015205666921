import { Box, MenuItem, TextField, Typography } from '@mui/material'
import { ContactToEdit } from './editContactModal'
import { getAllStates } from '../utils'

type AddressEditFieldsProps = {
  updatedContact: ContactToEdit
  setUpdatedContact: (next: ContactToEdit) => void
}

const AddressEditFields = ({ updatedContact, setUpdatedContact }: AddressEditFieldsProps) => {
  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setUpdatedContact({ ...updatedContact, [e.target.name]: e.target.value })
  }
  return (
    <Box className="flex flex-col gap-3 mb-4">
      <Box>
        <Typography className="text-grey-700 mb-1.5">Street</Typography>
        <TextField fullWidth name="street" value={updatedContact?.street} onChange={handleTextFieldChange} />
      </Box>
      <Box>
        <Typography className="text-grey-700 mb-1.5">City</Typography>
        <TextField fullWidth name="city" value={updatedContact?.city} onChange={handleTextFieldChange} />
      </Box>
      <Box className="grid grid-cols-2 gap-3">
        <Box>
          <Typography className="text-grey-700 mb-1.5">State</Typography>
          <TextField
            fullWidth
            onChange={(e) => {
              if (updatedContact) {
                setUpdatedContact({ ...updatedContact, state: e.target.value })
              }
            }}
            select
            value={updatedContact?.state}
          >
            {getAllStates().map((state) => (
              <MenuItem key={state.value} value={state.value}>
                {state.name}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box>
          <Typography className="text-grey-700 mb-1.5">Zip</Typography>
          <TextField name="zip" value={updatedContact?.zip} onChange={handleTextFieldChange} />
        </Box>
      </Box>
    </Box>
  )
}

export default AddressEditFields
